import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import metamask from '../assets/images/svg/metamask.svg'
import walletconnect from '../assets/images/svg/walletconnect.svg'
import { useWeb3React } from '@web3-react/core'
import { walletConnect, injected } from '../web3/connectors'
import CloseButton from './CloseButton'
import { useModalOpen, useWalletModalToggle } from '../state/application/hooks'
import { ApplicationModal } from '../state/application/reducer'
import { useRouter } from 'next/router'

const Modal = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const WalletWrap = styled.div`
  margin-top: 20px;
  display: flex;
`

const WalletTitle = styled.div`
  text-align: center;
  font-size: 28px;
  letter-spacing: -1px;
  color: #ffffff;
`

const WalletButton = styled.div`
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-shadow: 0px 3px 6px #00000029;
  &:hover {
    opacity: 0.7;
  }
`

const WalletIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const WalletCta = styled.div`
  margin-top: 10px;
  text-align: center;
  letter-spacing: -0.64px;
  color: #ffffff;
  font: normal normal bold 18px/20px typrighter;
`

const Spacer = styled.div`
  width: 8px;
`

const WalletModal = () => {
  const { activate, account } = useWeb3React()
  const router = useRouter()
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()
  // useEffect(() => {
  //   injected.isAuthorized().then((isAuthorized) => {
  //     if (isAuthorized) {
  //       activate(injected)
  //     }
  //   })
  // }, [activate])

  const goIntroPage = () => {
    if (router.pathname === '/intro') {
      location.reload()
    } else {
      router.push('/intro')
    }
  }

  const connectMetaMask = async () => {
    if (account) {
      goIntroPage()
    } else {
      await activate(injected, undefined, true)
      toggleWalletModal()
      goIntroPage()
    }
  }

  const connectWalletConnect = async () => {
    if (account) {
      goIntroPage()
    } else {
      await activate(walletConnect, (e) => {
        console.error(e)
        walletConnect.walletConnectProvider = undefined
        throw e
      })
        .then(() => {
          toggleWalletModal()
          goIntroPage()
        })
        .catch((e) => {
          console.error(e)
          walletConnect.walletConnectProvider = undefined
        })
    }
  }

  return (
    <>
      {walletModalOpen && (
        <Modal>
          {/* <CloseButton onClose={() => toggleWalletModal()} /> */}
          <WalletTitle>To begin, please connect your wallet…</WalletTitle>

          <WalletWrap>
            <WalletButton onClick={connectMetaMask}>
              <WalletIcon>
                <Image src={metamask} alt="MetaMask" width={121} height={112} />
              </WalletIcon>
              <WalletCta>Connect your MetaMask Wallet</WalletCta>
            </WalletButton>

            <Spacer />

            <WalletButton onClick={connectWalletConnect}>
              <WalletIcon>
                <Image src={walletconnect} alt="WalletConnect" width={121} height={121} />
              </WalletIcon>
              <WalletCta>Scan with WalletConnect</WalletCta>
            </WalletButton>
          </WalletWrap>
        </Modal>
      )}
    </>
  )
}

export default WalletModal
