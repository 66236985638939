// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools';

// 2. Add your color mode config -- dark mode
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const styles = {
  global: props => ({
    body: {
      color: mode('white', 'whiteAlpha.900')(props),
      bg: mode('black', '#000000')(props),
    },
    '.chakra-modal__content-container': {
      backdropFilter: 'blur(4px)',
      outline: 'none',
      '.chakra-modal__content': {
        outline: 'none',
        maxWidth: 'unset',
        background: 'transparent',
      },
      '.chakra-modal__body': {
        textAlign: 'center',
        textAlign: '-webkit-center',
        background: 'transparent',
        padding: '0'
      }
    }
  }),
};

// 3. extend the theme
const theme = extendTheme({ styles })

export default theme