import React, { useEffect, useState } from 'react'
import { Heading, Box, Button, SimpleGrid, Center, Image } from '@chakra-ui/react'
import lottie from 'lottie-web'

const VideoComponent = () => {
  const [play, setPlay] = useState(true)
  return (
    <>
      {play && (
        <video
          width={document.body.clientWidth * 0.02}
          height={document.body.clientWidth * 0.03}
          autoPlay={false}
          onClick={(evt: any) => {
            // debugger;
            evt.target.play()
          }}
          onEnded={() => {
            setPlay(false)
          }}
        >
          <source src="../videos/glitching-number-moshed.mp4" type="video/mp4" />
        </video>
      )}
    </>
  )
}

export default function Tip() {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('one'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/json/37674.json'
    })
  }, [])
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('two'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/json/37674.json'
    })
  }, [])
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('three'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/json/37674.json'
    })
  }, [])
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('four'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/json/37674.json'
    })
  }, [])
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('five'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/json/37674.json'
    })
  }, [])

  return (
    <>
      <Box
        bgImage="/images/home/home-page-bg.png"
        bgRepeat="no-repeat"
        bgSize="100%"
        bgPos="center 0"
        bgColor="#000000"
        h="100vh"
        w="100vw"
        m="0 auto"
      >
        <Image
          zIndex={999}
          position="absolute"
          right={0}
          top="0"
          alt=""
          src="./images/Disconnected_Wallet.svg"
          width="4.94792vw"
          height="5.88542vw"
        ></Image>
        <Center h="100vh" w="100vw" minW="100vw" backdropFilter="brightness(10%)">
          <SimpleGrid columns={1} spacingX="2.08vw" spacingY="1.0416vw">
            <Center bgImage="/images/merry-modz-logo-lg.png" bgRepeat="no-repeat" bgSize="80%" bgPos="center 0" h="26.0416vw"></Center>
            <Center>
              <Heading fontFamily="MerryChristmasStar" fontSize="3vw" fontWeight="light" display="block">
                Sorry, the Christmas Countdown is only available on larger screens
              </Heading>
            </Center>
          </SimpleGrid>
        </Center>
      </Box>
      <Box position="absolute" left="5.2vw" top="0">
        <div style={{ position: 'absolute', bottom: '1.697vw', left: '1vw', zIndex: 1 }}>
          <VideoComponent />
        </div>
        <Image alt="" src="./images/home/red-monitor@2x.png" width="9.3229vw" height="24.290625vw"></Image>
        <div id="one" style={{ width: '9.3229vw', height: '9.3229vw', position: 'absolute', bottom: '0.697vw' }}></div>
      </Box>

      <Box position="absolute" left="11.5625vw" top="0">
        <div style={{ position: 'absolute', bottom: '1.697vw', left: '1vw', zIndex: 1 }}>
          <VideoComponent />
        </div>
        <Image alt="" src="./images/home/gold-monitor@2x.png" width="9.3229vw" height="40.03125vw"></Image>
        <div id="two" style={{ width: '9.3229vw', height: '9.3229vw', position: 'absolute', bottom: '0.697vw' }}></div>
      </Box>

      <Box position="absolute" left="22.4vw" top="0">
        <div style={{ position: 'absolute', bottom: '0.697vw', left: '0.3vw', zIndex: 1 }}>
          <VideoComponent />
        </div>
        <Image alt="" src="./images/home/blue-monitor@2x.png" width="9.3229vw" height="31.2239583vw"></Image>
        <div id="three" style={{ width: '9.3229vw', height: '9.3229vw', position: 'absolute', bottom: '0.697vw' }}></div>
      </Box>

      <Box position="absolute" right="11.61vw" top="0">
        <div style={{ position: 'absolute', bottom: '1.5vw', left: '1.3vw', zIndex: 1 }}>
          <VideoComponent />
        </div>
        <Image alt="" src="./images/home/purple-monitor@2x.png" width="9.3229vw" height="40.68vw"></Image>
        <div id="four" style={{ width: '9.3229vw', height: '9.3229vw', position: 'absolute', bottom: '0.697vw' }}></div>
      </Box>

      <Box position="absolute" right="22.24vw" top="0">
        <div style={{ position: 'absolute', bottom: '1.5vw', left: '1.3vw', zIndex: 1 }}>
          <VideoComponent />
        </div>
        <Image alt="" src="./images/home/green-monitor@2x.png" width="9.3229vw" height="44.83vw"></Image>
        <div id="five" style={{ width: '9.3229vw', height: '9.3229vw', position: 'absolute', bottom: '0.697vw' }}></div>
      </Box>
    </>
  )
}
