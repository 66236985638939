import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injected = new InjectedConnector({
  supportedChainIds: [1,3,4,5],
});

export const walletConnect = new WalletConnectConnector({
  supportedChainIds: [1,3,4,5],
  chainId: Number(process.env.NEXT_PUBLIC_CHAIN_ID) ?? 5,
  infuraId: "72c1d9f6b81d425bb5b3375b6eaaa014",
  qrcode: true,
});
