import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";

export default function EnterPassword({ handleClose }) {
  const [password, setPassword] = useState("");
  const [failure, setFailure] = useState(false);

  const submit = async () => {
    handleClose(true);
    const response = await fetch("/api/check-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
      }),
    });

    const content = await response.json();

    if (content.verification) {
      localStorage.setItem("foundPassword", password);
      handleClose(true);
    } else {
      setFailure(true);
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Password Required</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool{" "}
            <Link color={"blue.400"}> Development features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={() => submit()}
              >
                Enter Preview
              </Button>

              {failure ? (
                <Text fontSize={"lg"} color={"red.600"}>
                  Incorrect, please try again.
                </Text>
              ) : null}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
