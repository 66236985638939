import { ChakraProvider, useMediaQuery } from '@chakra-ui/react'
import theme from '../theme'
import EnterPassword from '../components/EnterPassword'
import { useEffect, useState } from 'react'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers'
import { Provider } from 'react-redux'
import store from '../state'
import WalletModal from '../components/WalletModal'
import Tip from '../components/Tip'

import '../styles/globals.css'
import { useAvailable } from '../services/module/user'
import { useRouter } from 'next/router'

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 8000 // frequency provider is polling
  return library
}

function AppWrapper({ Component, pageProps, passwordCorrect, setPasswordCorrect }) {
  const [loading, setLoading] = useState(true)
  const [isLess1280] = useMediaQuery('(max-width: 1280px)')
  const available = useAvailable()
  const router = useRouter()

  useEffect(() => {
    if (!available) {
      router.push('/expire')
    }
  }, [available])
  useEffect(() => {
    const password = localStorage.getItem('foundPassword')
    setPasswordCorrect(true)
    const checkPassword = async (password) => {
      const response = await fetch('/api/check-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password
        })
      })

      const content = await response.json()

      if (content.verification) {
        setPasswordCorrect(true)
        setLoading(false)
      } else {
        setPasswordCorrect(false)
        setLoading(false)
      }
    }

    if (password) {
      checkPassword(password)
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <div>Loading</div>

  return isLess1280 ? (
    <Tip></Tip>
  ) : process.env.NEXT_PUBLIC_PASSWORD_PROTECTED == 'true' && !passwordCorrect ? (
    <EnterPassword handleClose={setPasswordCorrect} />
  ) : (
    <>
      <Component {...pageProps} />
      <WalletModal></WalletModal>
    </>
  )
}

function MyApp({ Component, pageProps }) {
  const [passwordCorrect, setPasswordCorrect] = useState(false)

  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <ToastContainer position="top-right" hideProgressBar={true} autoClose={2500} />
        <Web3ReactProvider getLibrary={getLibrary}>
          <AppWrapper
            Component={Component}
            pageProps={pageProps}
            passwordCorrect={passwordCorrect}
            setPasswordCorrect={setPasswordCorrect}
          />
        </Web3ReactProvider>
      </Provider>
    </ChakraProvider>
  )
}

export default MyApp
