import { useCallback, useEffect, useState } from 'react'

export function useCountdown() {
  const [date, setDate] = useState<string>('0')

  const setData = useCallback(async () => {
    const todaysMappingId = await fetch(`/api/currentDay`).then((response) => response.json())
    setDate(todaysMappingId)
  }, [])

  useEffect(() => {
    setData()
  }, [setData])
  return date
}

export function useAvailable() {
  const [available, setAvailable] = useState<boolean>(true)

  const setData = useCallback(async () => {
    const _available = await fetch(`/api/available`).then((response) => response.json())
    setAvailable(_available)
  }, [])

  useEffect(() => {
    setData()
  }, [setData])
  return available
}


export function useTodaysMappingId() {
  const [date, setDate] = useState<string>('0')

  const setData = useCallback(async () => {
    const todaysMappingId = await fetch(`/api/getTodaysMappingId`).then((response) => response.json())
    setDate(todaysMappingId)
  }, [])

  useEffect(() => {
    setData()
  }, [setData])
  return date
}

export function useBoardState(address: string, flag: boolean): any {
  const [boardState, setBoardState] = useState()

  const setData = useCallback(async () => {
    if (address) {
      const result = await fetch(`/api/${address}/getBoardState`).then((response) => response.json())
      setBoardState(result)
    }
  }, [address])

  useEffect(() => {
    setData()
  }, [setData, flag])
  return boardState
}
